import 'moment/dist/locale/fr';
import 'moment/locale/fr';

// useful for warning
// import 'mobx-react-lite/batchingForReactDom';
import ConfigProvider                           from 'antd/lib/config-provider';
import locale                                   from 'antd/locale/fr_FR';
import { changeAntdTheme }                      from 'mini-dynamic-antd-theme';
import { observer }                             from 'mobx-react';
import moment                                   from 'moment';
import {
	Route,
	BrowserRouter as Router,
	Routes,
}                                               from 'react-router-dom';
import React                                    from 'react';
import ConfigProxy                              from 'tools/ConfigProxy';
import PrivateRoute                             from './components/PrivateRoute';
import ContactPage                              from './pages/private/ContactPage';
import ContactsListPage                         from './pages/private/ContactsListPage/ContactsListPage';
import ContractDashboardPage                    from './pages/private/ContractDashboardPage/ContractDashboardPage';
import FileDashboardPage                        from './pages/private/FileDashboardPage/FileDashboardPage';
import FilesListPage                            from './pages/private/FilesListPage/FilesListPage';
import FrameworkAgreementIterationDashboardPage from './pages/private/FrameworkAgreementIterationDashboardPage/FrameworkAgreementIterationDashboardPage';
import FrameworkAgreementIterationListPage      from './pages/private/FrameworkAgreementIterationListPage';
import FunctionsListPage                        from './pages/private/FunctionsListPage/FunctionsListPage';
import HomePage                                 from './pages/private/HomePage/HomePage';
import InterventionListCompletedPage            from './pages/private/InterventionListCompletedPage/InterventionListCompletedPage';
import InterventionListFuturePage               from './pages/private/InterventionListFuturePage/InterventionListFuturePage';
import MyAccountPage                            from './pages/private/MyAccountPage';
import MyContractListPage                       from './pages/private/MyContractListPage';
import MyInvoiceListPage                        from './pages/private/MyInvoiceListPage/MyInvoiceListPage';
import MyQuotationListPage                      from './pages/private/MyQuotationListPage';
import QuotationDashboardPage                   from './pages/private/QuotationDashboardPage/QuotationDashboardPage';
import ExportPage                               from './pages/public/ExportPage/ExportPage';
import ForgottenPasswordPage                    from './pages/public/ForgottenPasswordPage';
import InterventionValidationPage               from './pages/public/InterventionValidationPage';
import LoginImpersonatePage                     from './pages/public/LoginImpersonatePage';
import NoMatchPage                              from './pages/public/NoMatchPage';

import ResetPasswordPage                        from './pages/public/ResetPasswordPage';
// eslint-disable-next-line arca/import-ordering
import './css/App.scss';
import './css/antDesign.scss';
import './css/index.scss';

moment.locale('fr');

const setFavicon = (href) => {
	const linkIconList: NodeListOf<HTMLLinkElement> = document.querySelectorAll('link[rel~=\'icon\']');

	linkIconList.forEach(linkIcon => {
		if (linkIcon && linkIcon.href) {
			linkIcon.href = href;
		}
	});
};

class App extends React.Component {

	componentDidMount() {
		
		switch (ConfigProxy.get('PARTITION_URN')) {
			case '$:partition:partition:1':
				changeAntdTheme('#00a28b');
				setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
				break;
			case '$:partition:partition:2':
				changeAntdTheme('#c0024a');
				setFavicon(new URL('/favicons/favicon-dicop.png', import.meta.url).href);
				break;
			case '$:partition:partition:3':
				changeAntdTheme('#00a28b');
				setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
				break;
			case '$:partition:partition:4':
				changeAntdTheme('#00a28b');
				setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
				break;
			case '$:partition:partition:5':
				changeAntdTheme('#00a28b');
				setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
				break;
			case '$:partition:partition:6':
				changeAntdTheme('#026D70');
				setFavicon(new URL('/favicons/favicon-rcc.png', import.meta.url).href);
				break;
			case '$:partition:partition:7':
				changeAntdTheme('#00aeef');
				setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
				break;
			case '$:partition:partition:8':
				changeAntdTheme('#c0024a');
				setFavicon(new URL('/favicons/favicon-adiams.png', import.meta.url).href);
				break;
			case '$:partition:partition:9':
				changeAntdTheme('#00aeef');
				setFavicon(new URL('/favicons/blank.png', import.meta.url).href);
				break;
			case '$:partition:partition:10':
				changeAntdTheme('#0A74BB');
				setFavicon(new URL('/favicons/favicon-mapclim.png', import.meta.url).href);
				break;
			case '$:partition:partition:11':
				changeAntdTheme('#009EE2');
				setFavicon(new URL('/favicons/favicon-d2h.png', import.meta.url).href);
				break;
			case '$:partition:partition:12':
				changeAntdTheme('#009EE2');
				setFavicon(new URL('/favicons/favicon-zaack.png', import.meta.url).href);
				break;
			case '$:partition:partition:14':
				changeAntdTheme('#0A74BB');
				setFavicon(new URL('/favicons/favicon-addd.png', import.meta.url).href);
				break;
			case '$:partition:partition:15':
				changeAntdTheme('#2A96D4');
				setFavicon(new URL('/favicons/favicon-agetep.png', import.meta.url).href);
				break;
			case '$:partition:partition:16':
				changeAntdTheme('#00a28b');
				setFavicon(new URL('/favicons/favicon-igc.png', import.meta.url).href);
				break;
			case '$:partition:partition:17':
				changeAntdTheme('#009FE3');
				setFavicon(new URL('/favicons/favicon-inter.png', import.meta.url).href);
				break;
			case '$:partition:partition:18':
				changeAntdTheme('#00a28b');
				setFavicon(new URL('/favicons/favicon-airplusnetouest.png', import.meta.url).href);
				break;
			default:
		}
	}

	render() {
		return (
			<div className="App">
				<ConfigProvider
					getPopupContainer={() => {
						return document.getElementById('main') || document.body;
					}}
					locale={locale}
					theme={{
						hashed: false,
						token: {
							borderRadius: 2
						}
					}}
				>
					<Router>
						<Routes>	
							<Route element={<LoginImpersonatePage />} path="impersonate" />

							<Route element={<ForgottenPasswordPage />} path="forgotten-password" />

							<Route element={<ResetPasswordPage />} path="reset-password" />

							<Route element={<InterventionValidationPage />} path="intervention_validation/:token" />

							<Route element={<PrivateRoute><HomePage /></PrivateRoute>} path="" />

							<Route element={<PrivateRoute><MyAccountPage /></PrivateRoute>} path="my-account" />

							<Route path="enterprises/:enterpriseId">

								<Route element={<PrivateRoute><HomePage /></PrivateRoute>} path="" />
								<Route path="establishments/:clientPartitionId">

									<Route element={<PrivateRoute><HomePage /></PrivateRoute>} path="" />
									<Route path="zones/:taskZoneId">

										<Route element={<PrivateRoute><ExportPage /></PrivateRoute>} path="exports/:exportId" />

										<Route element={<PrivateRoute><HomePage /></PrivateRoute>} path="" />

										<Route element={<PrivateRoute><MyContractListPage /></PrivateRoute>} path="contracts" />

										<Route element={<PrivateRoute><ContractDashboardPage /></PrivateRoute>} path="contracts/:id" />

										<Route element={<PrivateRoute><MyQuotationListPage /></PrivateRoute>} path="quotations" />

										<Route element={<PrivateRoute><FrameworkAgreementIterationListPage /></PrivateRoute>} path="framework_agreements" />

										<Route element={<PrivateRoute><FrameworkAgreementIterationDashboardPage /></PrivateRoute>} path="framework_agreements/:id" />

										<Route element={<PrivateRoute><QuotationDashboardPage /></PrivateRoute>} path="quotations/:id" />

										<Route element={<PrivateRoute><MyInvoiceListPage /></PrivateRoute>} path="my-invoices" />

										<Route element={<PrivateRoute><InterventionListFuturePage /></PrivateRoute>} path="future-interventions" />

										<Route element={<PrivateRoute><InterventionListCompletedPage /></PrivateRoute>} path="completed-interventions" />

										<Route element={<PrivateRoute><FilesListPage /></PrivateRoute>} path="files" />

										<Route element={<PrivateRoute><FileDashboardPage /></PrivateRoute>} path="files/:fileId" />

										<Route element={<PrivateRoute><ContactsListPage /></PrivateRoute>} path="contacts" />

										<Route element={<PrivateRoute><FunctionsListPage /></PrivateRoute>} path="contact-data-points" />

										<Route element={<PrivateRoute><ContactPage /></PrivateRoute>} path="contact" />

										<Route element={<NoMatchPage />} path="*" />
									</Route>
								</Route>
							</Route>
						</Routes>
					</Router>
				</ConfigProvider>
			</div>
		);
	}
}

export default observer(App);
